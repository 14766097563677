.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.containerPage {
  padding-top: 80px;
}

.video_index {
  position: relative;
  height: calc(100vh - 64px);
  
  background-color: white;
  /* width: 100%; */
  /* height: 100%; */
  scroll-snap-align: start;
}

.video {
  position: relative;
  height: calc(100vh - 64px);
  background-color: white;
  /* width: 100%; */
  /* height: 100%; */
  scroll-snap-align: start;
}

.video__player {
  object-fit: fill;
  width: 100%;
  height: 100%;
}

.allVideos 
{
  margin-top: 64px!important;
  scroll-snap-type: y mandatory;
  margin: auto
}

.videoSidebar {
  position: absolute;
  top: 5%;
  right: 5px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.videoSidebar__button {
  padding: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.videoFooter {
  position: absolute;
  color: white;
  bottom: 20px;
  padding: 10px;
  /* background-color: #61dafb; */
}

.textShadow {
  text-shadow: 2px 2px 2px #000000;
}

.svgIconShadow {
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.9));
}

/* width */
::-webkit-scrollbar {
  width: 10px;/*for scrollY*/
  height: 3px;/*for scrollX*/
}

/* Track */
::-webkit-scrollbar-track {
  background: #2c2c2c;  
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #fdf800;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fdf900cc;
}

.activeTabHome {
  color: #fff;
  text-transform: "uppercase";
  text-decoration:"underline"
}

.inactiveTabHome {
  color:#fff;
  text-transform: "uppercase";
  text-decoration:"inherit"
}
